<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import useOutboundOrders from "@/hooks/useOutboundOrders";
import OutboundOrderLayout from "@/layouts/OutboundOrderLayout.vue";

const { outboundLoading, outboundOrder, fetchOutboundOrder } = useOutboundOrders();
const route = useRoute();

onMounted(async () => {
  await fetchOutboundOrder(route.params.id).then(() => {
    setPageTitle(`Outbound Order #OUT-${outboundOrder.value.id}`);
  });
});
</script>

<template>
  <OutboundOrderLayout :outboundOrder="outboundOrder">
    <div class="w-100">
      <div v-if="!outboundLoading && outboundOrder" class="px-4">
        <div class="w-100">
          <div class="w-auto px-2">
            <v-card class="pa-2">
              <v-card-title class="d-flex justify-space-between">
                <div>
                  {{outboundOrder.client.nickname}}/TRANSPORT FOR OUTBOUND ORDER #OUT-{{ outboundOrder.id }}
                </div>
                <div>
                  <v-chip color="yellow"
                    >STATUS:
                    <span class="font-weight-bold ml-1">{{ outboundOrder.order_status.short_label.toUpperCase() }}</span></v-chip
                  >
                </div></v-card-title
              >
            </v-card>
          </div>

          <v-card class="mt-2 pa-2 w-auto" color="transparent" elevation="0">
            <v-card>
              <v-card-title>Bill of Lading Details</v-card-title>
              <v-card-text>
                  <v-table>
                    <thead class="bg-surface-light">
                      <tr>
                        <th>QTY</th>
                        <th>UNITS</th>
                        <th>GOODS</th>
                        <th>WT (LBS)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td class="text-right">Weight of Items</td>
                        <td>items_weight</td>
                      </tr>
                      <tr>
                        <td style="width: 200px;">
                          <v-text-field
                            density="compact"
                            type="number"
                            hide-details
                            variant="outlined"
                            label="Total Quantity"
                          />
                        </td>
                        <td style="width: 200px;">
                          <v-text-field
                            density="compact"
                            hide-details
                            variant="outlined"
                            label="Units"
                          />
                        </td>
                        <td>
                          <v-text-field
                            density="compact"
                            hide-details
                            variant="outlined"
                            label="Cargo"
                          />
                        </td>
                        <td style="width: 200px;">
                            <v-text-field
                              density="compact"
                              type="number"
                              hide-details
                              variant="outlined"
                              label="Weight"
                            />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <v-textarea
                            density="compact"
                            hide-details
                            variant="outlined"
                            label="Special Instructions:"
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </v-table>
              </v-card-text>
            </v-card>

            <v-card class="mt-4">
              <v-card-title>Tracking</v-card-title>
              <v-card-text>
                <v-row class="d-flex align-center">
                  <v-col cols="12" md="2">Tracking Link</v-col>
                  <v-col cols="12" md="10">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      hide-details
                      placeholder="Leave blank if no tracking link" 
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <div class="mt-6 text-center">
              <v-btn
                color="success"
                variant="flat"
              >
                Save
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </OutboundOrderLayout>
</template>
